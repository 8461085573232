import { Await } from '@remix-run/react'
import { JSX, Suspense } from 'react'
import NotFoundFallback from './NotFoundFallback'

type DeferredSkeletonProps<T> = {
  promise: Promise<T>
  children: (data: T) => React.ReactNode
  fallback?: React.ReactNode
  errorElement?: React.ReactNode
  handleNotFound?: boolean
}

const DeferredSkeleton = <T,>({
  promise,
  children,
  fallback = <></>,
  errorElement = null,
  handleNotFound = true,
}: DeferredSkeletonProps<T>): JSX.Element => {
  const errorElementFallback = handleNotFound ? (
    <NotFoundFallback />
  ) : (
    errorElement
  )

  return (
    <Suspense fallback={fallback}>
      <Await resolve={promise} errorElement={errorElementFallback}>
        {(resolvedData: T) => children(resolvedData)}
      </Await>
    </Suspense>
  )
}

export default DeferredSkeleton
